<template>
  <v-container fluid>
    <v-card>
      <v-card-title>Avvia Nuovo Bot</v-card-title>
      <v-card-text>
        <VAEditForm @submitted="onSubmitted" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { merge } from 'lodash'
import VAEditForm from '@components/virtualagents/VAEditForm.vue'

export default {
  name: 'DealerCreate',
  page: {
    title: 'Creazione Ticket',
  },
  components: { VAEditForm },
  computed: {},
  methods: {
    ...mapActions('virtualAgentsSessions', ['create']),
    ...mapMutations('virtualAgentsSessions', { setSessionData: 'SET_CURRENT' }),

    async onSubmitted(id) {
      const sessionData = [
        'arrears',
        'loyalty',
        'skill_type',
        'first_time',
        'sentiment',
        'spending_range',
        'approach',
        'reason',
        'service',
      ]
        .map((key) => ({ [key]: this[key] }))
        .reduce(merge)
      this.setSessionData(sessionData)
      await this.create()
      this.$router.push({ name: 'virtualagents_sessions_index' })
    },
  },
}
</script>
