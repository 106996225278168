<template>
  <div>
    <v-card-subtitle v-if="paramFields.length" class="text-center overline">
      4. Inserisci ulteriori dettagli
    </v-card-subtitle>

    <v-row justify="center">
      <FormItem
        v-for="field in paramFields"
        :key="field.key"
        v-model="field.value"
        :name="field.name"
        :clearable="field.clearable"
        :type="field.type"
        :values="field.values"
        :single="field.single"
        :cols="field.cols"
        :rules="field.rules"
        :md="field.md"
        :lg="field.lg"
        :xl="field.xl"
        @input="(value) => onChange(field.key)(value)"
      />
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FormItem from '@components/forms/FormItem.vue'
import { createHelpers } from 'vuex-map-fields'
const { mapFields: mapSession } = createHelpers({
  getterType: 'virtualAgentsSessions/getCurrent',
  mutationType: 'virtualAgentsSessions/SET_CURRENT_FIELDS',
})

export default {
  name: 'VaParamsFields',
  components: { FormItem },
  data: () => ({}),
  computed: {
    ...mapState('virtualAgentsPresets', ['paramFields']),
    ...mapSession(['params']),
  },
  methods: {
    onChange(key) {
      return (value) => {
        const { searchable } = this.paramFields.find((i) => i.key === key)
        console.log(key + ' --> ' + value, searchable)

        this.params[key] = value
      }
    },
  },
}
</script>

<style scoped></style>
