<template>
  <div>
    <p class="text-center mb-12">
      {{ message }}
    </p>
    <v-fade-transition>
      <v-row v-if="pagination.rows">
        <v-col v-for="item in pagination.rows" :key="item.id">
          <VaRobotButtn
            :id="item.id"
            :description="getDescription(item)"
            :label="item.name"
            :selected="type === item.id"
            :type="getType(item)"
            @input="onSelected"
          />
        </v-col>
      </v-row>
    </v-fade-transition>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import VaRobotButtn from '@components/virtualagents/wizard/VaRobotButton.vue'
import { curry, flow, get, property, toLower } from 'lodash'

const { mapFields: mapPresetsFilters } = createHelpers({
  getterType: 'virtualAgentsPresets/getFiltersFields',
  mutationType: 'virtualAgentsPresets/SET_FILTER_FIELDS',
})
const { mapFields: mapSession } = createHelpers({
  getterType: 'virtualAgentsSessions/getCurrent',
  mutationType: 'virtualAgentsSessions/SET_CURRENT_FIELDS',
})

const getFrom = curry(get, 2)

export default {
  name: 'VaPresetSelector',
  components: { VaRobotButtn },
  computed: {
    ...mapPresetsFilters(['skill_type', 'reason', 'service']),
    ...mapSession(['preset', 'type', 'params']),
    ...mapState('virtualAgentsPresets', ['list', 'pagination', 'loading']),
    ...mapGetters('virtualAgentsPresets', ['labels']),
    observableKey() {
      return [this.skill_type, this.reason, this.service]
    },
    disabled() {
      return this.observableKey.some((i) => !i)
    },
    hasManyPages() {
      return this.pagination.totalPages > 1
    },
    message() {
      if (this.disabled) return `Valorizza i campi del punto 2 per procedere. `

      if (!this.pagination.totalRows)
        return `Non è stato trovato nessun agente virtuale che corrisponda ai criteri inseriti`

      if (this.pagination.totalRows < 2)
        return `È stato trovato un agente virtuale in grado di gestire l'esigenza.`

      return `Sono stati trovati ${this.pagination.totalRows} agenti virtuali in grado di gestire l'esigenza.`
    },
  },
  data: () => ({
    initialCtx: {
      itemsPerPage: 3,
      page: 1,
    },
  }),
  watch: {
    observableKey: {
      immediate: true,
      handler: 'onFilterUpdated',
    },
  },
  methods: {
    ...mapActions('virtualAgentsPresets', ['getItems']),
    ...mapMutations('virtualAgentsPresets', {
      resetItems: 'RESET_PAGINATED_ITEMS',
      setParamFields: 'SET_PARAM_FIELDS',
    }),

    async onFilterUpdated() {
      console.info('onFilterUpdated', this.observableKey)
      if (this.disabled) {
        this.type = null
        this.resetItems()
        // reset field lists and values
        this.setParamFields([])
        this.params = {}
        return
      }

      this.page = 1
      await this.getItems(this.initialCtx)

      // just one bot found, select it
      const { id } = this.pagination.rows[0]
      this.onSelected(id)
    },

    onSelected(item) {
      this.type = item

      const current = this.pagination.rows.find((i) => i.id === item)
      this.setParamFields(current?.params_fields)
    },

    nextPage(pageNum) {
      this.page = pageNum
      this.getItems({ ...this.initialCtx, page: this.page })
    },

    getType: flow([
      property('role'),
      getFrom({
        assertive: 'happy',
        broker: 'neutral',
        severe: 'angry',
      }),
    ]),
    getLabel: property('name'),
    getDescription(item) {
      const role = toLower(getFrom(this.labels('roles'))(item.role))
      // const attitude = toLower(getFrom(this.labels('attitudes'))(item.attitude))
      return `<strong class="text-overline">${role}</strong> <br><span class="text-caption">Fidelizzazione: ${item.fidelity} - Sviluppo: ${item.growth}</span> `
    },
  },
}
</script>

<style scoped></style>
