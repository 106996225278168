<template>
  <div>
    <v-row>
      <v-col></v-col>
      <FormCustomers
        v-model="customer_id"
        clearable
        name="Cerca Cliente"
        hint="Ricerca per Ragione S3ociale, Partita IVA o numero di telefono"
        persistent-hint
        :cols="12"
        :md="6"
        :lg="5"
        :xl="5"
        @input="onCustomerSelected"
      />
      <v-col></v-col>
    </v-row>
    <v-row>
      <FormItem
        v-model="common_name"
        clearable
        name="Ragione Sociale"
        rules="required"
        :cols="12"
        :md="4"
        :lg="3"
        :xl="3"
      />
      <FormItem
        v-model="first_name"
        clearable
        name="Nome"
        rules="required"
        :cols="12"
        :md="4"
        :lg="2"
        :xl="2"
      />
      <FormItem
        v-model="last_name"
        clearable
        name="Cognome"
        rules="required"
        :cols="12"
        :md="4"
        :lg="2"
        :xl="2"
      />
      <FormItem
        v-model="mobile_phone_number"
        clearable
        name="Numero di Cellulare"
        rules="required|phone"
        :cols="12"
        :md="3"
        :lg="2"
        :xl="2"
      />
      <FormItem
        v-model="email"
        clearable
        name="Email"
        rules="email"
        :cols="12"
        :md="4"
        :lg="3"
        :xl="3"
      />
    </v-row>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import FormCustomers from '@components/forms/FormCustomers.vue'
import FormItem from '@components/forms/FormItem.vue'
import { pick } from 'lodash'

const { mapFields: mapSession } = createHelpers({
  getterType: 'virtualAgentsSessions/getCurrent',
  mutationType: 'virtualAgentsSessions/SET_CURRENT_FIELDS',
})
export default {
  name: 'VaCustomerInfo',
  components: { FormCustomers, FormItem },
  computed: {
    ...mapSession([
      'customer_id',
      'common_name',
      'first_name',
      'last_name',
      'email',
      'mobile_phone_number',
    ]),
  },
  methods: {
    set(key) {
      return (value) => {
        this[key] = value
      }
    },
    async onCustomerSelected(id) {
      let changes = {
        common_name: null,
        customer_id: id,
        email: null,
        first_name: null,
        last_name: null,
        mobile_phone_number: null,
      }
      if (id) {
        await this.$store.dispatch('customers/setCurrent', id)
        // Format created at to a string in plain text
        const loyalty = this.$moment(
          this.$store.state.customers.current.created_at
        ).format('DD MMMM YYYY')
        // Merge the changes
        changes = {
          ...changes,
          loyalty,
          ...pick(this.$store.state.customers.current, [
            'common_name',
            'first_name',
            'last_name',
            'mobile_phone_number',
            'email',
          ]),
        }
      }
      Object.entries(changes).forEach(([key, value]) => this.set(key)(value))
      this.$store.commit('customers/RESET_CURRENT')
    },
  },
}
</script>

<style scoped></style>
