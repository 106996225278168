<template>
  <Form hide-reset send-text="Invia all'agente Virtuale" @submit="onSubmit">
    <v-card-subtitle class="text-center overline">
      1. Dettagli Cliente
    </v-card-subtitle>
    <VaCustomerInfo />

    <v-divider />

    <v-card-subtitle class="text-center overline">
      2. Seleziona la tematica di interesse del tuo Bot
    </v-card-subtitle>
    <v-row>
      <FormItem
        v-model="skill_type"
        :col="12"
        :lg="4"
        :md="4"
        :xl="4"
        name="Skill"
        type="autocomplete"
        single
        clearable
        :values="skillTypes"
      />
      <FormItem
        v-model="reason"
        :disabled="!skill_type"
        :col="12"
        :lg="4"
        :md="4"
        :xl="4"
        name="Motivo di contatto"
        type="autocomplete"
        clearable
        single
        :values="reasons"
      />
      <FormItem
        v-model="service"
        :disabled="!skill_type"
        :col="12"
        :lg="4"
        :md="4"
        :xl="4"
        name="Servizi"
        type="autocomplete"
        single
        clearable
        :values="services"
      />
    </v-row>

    <v-divider />

    <v-card-subtitle class="text-center overline">
      3. Seleziona l'agente virtuale
    </v-card-subtitle>
    <VaPresetSelector />

    <VaParamsFields />
  </Form>
</template>

<script>
import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'
import storeReset from '@mixins/storeReset'
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import VaPresetSelector from '@components/virtualagents/inc/VaPresetSelector.vue'
import VaCustomerInfo from '@components/virtualagents/inc/VaCustomerInfo.vue'
import VaParamsFields from '@components/virtualagents/inc/VaParamsFields.vue'

const { mapFields: mapPresetsFilters } = createHelpers({
  getterType: 'virtualAgentsPresets/getFiltersFields',
  mutationType: 'virtualAgentsPresets/SET_FILTER_FIELDS',
})

const { mapFields: mapSession } = createHelpers({
  getterType: 'virtualAgentsSessions/getCurrent',
  mutationType: 'virtualAgentsSessions/SET_CURRENT_FIELDS',
})

const { mapFields: mapReasonsFilters } = createHelpers({
  getterType: 'virtualAgentsReasons/getFiltersFields',
  mutationType: 'virtualAgentsReasons/SET_FILTER_FIELDS',
})

const { mapFields: mapServicesFilters } = createHelpers({
  getterType: 'virtualAgentsServices/getFiltersFields',
  mutationType: 'virtualAgentsServices/SET_FILTER_FIELDS',
})

export default {
  name: 'VAEditForm',
  components: {
    VaParamsFields,
    VaCustomerInfo,
    VaPresetSelector,
    Form,
    FormItem,
  },
  mixins: [storeReset],
  computed: {
    ...mapPresetsFilters(
      {
        skill_type: 'skill_type',
        preset_service: 'service',
        preset_reason: 'reason',
      }

      // ['sentiment', 'skill_type', 'spending_range']
    ),
    ...mapSession([
      'common_name',
      'email',
      'mobile_phone_number',
      'reason',
      'service',
      'customer_id',
    ]),
    ...mapReasonsFilters({
      r_skill_type: 'skill_type',
    }),
    ...mapServicesFilters({
      s_skill_type: 'skill_type',
    }),
    ...mapGetters('virtualAgentsPresets', ['skillTypes']),
    ...mapState('virtualAgentsPresets', ['paramFields']),
    ...mapState('virtualAgentsReasons', {
      reasons: 'list',
    }),
    ...mapState('virtualAgentsServices', {
      services: 'list',
    }),
  },
  watch: {
    reason(val) {
      this.preset_reason = val
    },

    service(val) {
      this.preset_service = val
    },

    skill_type: {
      immediate: false,
      handler: 'onSkillSelected',
    },
  },
  async mounted() {
    await this.getSkillTypes()
  },
  beforeDestroy() {
    this.storeReset({
      virtualAgentsPresets: ['paginated', 'filters'],
      virtualAgentsSessions: ['current'],
    })
  },
  methods: {
    // skill
    ...mapActions('virtualAgentsPresets', ['getSkillTypes']),
    // reason
    ...mapActions('virtualAgentsReasons', {
      getReasons: 'getDropdownList',
    }),
    ...mapMutations('virtualAgentsReasons', {
      resetReasons: 'RESET_LIST',
    }),
    // servizi
    ...mapActions('virtualAgentsServices', {
      getServices: 'getDropdownList',
    }),
    ...mapMutations('virtualAgentsReasons', {
      resetServices: 'RESET_LIST',
    }),

    ...mapActions('virtualAgentsSessions', ['create']),
    ...mapMutations('virtualAgentsSessions', { setSessionData: 'SET_CURRENT' }),

    async onSkillSelected(skill, old) {
      if (!skill || skill === old) return

      this.r_skill_type = skill
      this.s_skill_type = skill
      this.reason = null
      this.service = null
      await Promise.all([this.getReasons(), this.getServices()])
    },

    async onSubmit() {
      await this.create()
      this.$router.push({ name: 'virtualagents_sessions_index' })
    },
  },
}
</script>

<style scoped></style>
