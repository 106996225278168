<template>
  <v-fade-transition>
    <v-sheet
      v-ripple
      rounded
      :class="[
        'px-6 py-3 text-center',
        {
          ['blue lighten-5']: selected && !dark_mode,
          ['blue-grey darken-4']: selected && dark_mode,
        },
      ]"
    >
      <a @click="onClick">
        <v-img :src="icon" width="48" height="48" class="mx-auto mb-4" />
        <strong class="subtitle-1 font-weight-bold d-block">{{ label }}</strong>
        <span class="subtitle-2 text--secondary" v-html="description" />
      </a>
    </v-sheet>
  </v-fade-transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'VaRobotButtn',
  components: {},
  props: {
    description: {
      default: '',
      type: String,
    },
    label: {
      default: '',
      type: String,
    },
    selected: {
      default: false,
      type: Boolean,
    },
    id: {
      default: '',
      type: String,
    },
    type: {
      default: 'neutral',
      type: String,
      validator: (v) => ['angry', 'happy', 'neutral'].includes(v),
    },
  },
  computed: {
    ...mapState('ui', ['dark_mode']),
    icon() {
      return `/img/robot/${this.type}.svg`
    },
  },
  methods: {
    onClick() {
      this.$emit('input', this.id)
    },
  },
}
</script>
